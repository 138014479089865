































import Vue from 'vue';
import { Vaultifier, VaultifierWeb } from 'vaultifier/dist/main';

import { TDAService } from './tda-service';

interface Data {
  message: string,
}

export default Vue.extend({
  name: 'App',
  data: (): Data => ({
    message: 'Authenticating your Trusted Digital Assistant...',
  }),
  async created() {
    const vaultifier = await VaultifierWeb.create();

    if (vaultifier) {
      await vaultifier.initialize();
      await vaultifier.setEnd2EndEncryption();
      this.initializeTDA(vaultifier);
    }
    else
      this.setErrorMessage();
  },
  methods: {
    setErrorMessage() {
      this.message = 'TDA could not be started.';
    },
    async initializeTDA(vaultifier: Vaultifier) {
      const { content: configurationItems } = await vaultifier.getValues({
        // That's the dri of "ConfigurationItem", basically it's a key value pair
        schemaDri: '4ktjMzvwbhAeGM8Dwu67VcCnuJc52K3fVdq7V1qCPWLw',
      });

      const backend = configurationItems.find((x: any) => x.key === 'tda.backend.uri');
      const frontend = configurationItems.find((x: any) => x.key === 'tda.frontend.uri');

      if (backend && frontend) {
        const tdaFrontendUrl = frontend.value as string;
        const tdaBackendUrl = backend.value as string;

        const tdaService = TDAService.getInstance();
        if (vaultifier.credentials) {
          const { appKey, appSecret, scope } = vaultifier.credentials;
          const { oAuth } = await vaultifier.getVaultSupport();
          const { name } = await vaultifier.getVaultInfo();

          if (appKey && appSecret && oAuth) {
            tdaService.setTDAUrl(tdaBackendUrl);
            await tdaService.setupPDSSettings(
              vaultifier.urls.baseUrl,
              appKey,
              appSecret,
              oAuth.type,
              name,
              scope,
            );
          }
        }

        const url = await tdaService.createAdminInvitationUrl(tdaFrontendUrl);

        this.message = 'Starting your Trusted Digital Assistant...';

        if (url) {
          window.location.href = url;
          return;
        }
      }

      this.setErrorMessage();
    }
  }
});
